/* colors.css */

:root {
    /* Define your color variables here */
    --light-body-color: rgb(230, 230, 230);
    --dark-body-color: #121212;
    --color-white: #ffffff;
    --sn-orange: #F59C25;
    --sn-dark-pink: #D774AE;
    --sn-light-blue: #78C3ED;
    --sn-light-green: #79CAC1;
    --sn-light-red: #F84C40;
    --darker-grey: #212121;
    --darkest-grey: #252525;
    --color-dark: #252525;
    --color-darker-foreground:#191919;

  }
  