.security_popup-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.534);
    backdrop-filter: blur(10px); /* Apply blur effect */

    justify-content: center;
    align-items: center;
    z-index: 9999!important;
  }

.security_text{
  text-align: center;
  margin-bottom: 22px;
  
  }

  .security_popup-content p {
    line-height: 1;
    text-align: center;
  }
  
  .security_popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .show {
    display: flex;
  }
  
  .hide {
    display: none;
  }

  .security_popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center the content */
    margin-bottom: 20px;
  }
  
  .security_popup-content input[type="password"]
 {
    margin-top: 40px; /* Add some space between the text and the input/button */
    display: block; /* Set these elements to display as blocks */
    margin: 0 auto; /* Center the input and button horizontally */
    margin-bottom: 20px;
  }

  .unity__button_security { 
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 0, 0, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 60px;
  min-width: 80px;


  color: var(--color-white);
  /* COLOUR FOR BUTTON TEXT */
}

.unity__button_security:hover,
.unity__button_security.current {
  border-color: #000000;
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--color-white) !important;
  /* Set text color to white for hover and current */
}
  