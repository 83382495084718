/* Add this CSS code to your project */

/* Customize the navbar background, text, and link colors */






  /* Logo styles */
  .logo_header img{
    margin-top: 20px;
    max-width: 150px;
    cursor: pointer;
  }

  @media (min-width: 1200px){
.d-lg-none {
    display: none !important;
}
  }
 
  