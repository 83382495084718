/* iFrame Container for Unity Pages */
.unity_iframe__container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: transparent !important;
}

.iframe_new {
  height: 100%;
  width: 100%;
  border: none;
}

/* Define the grid layout for the row */
.unity_row {
  position: relative;
  /* Set the container as relative to position the col-4 absolutely */
}

/* Left column (Unity iFrame) */
.unity_col-lg-8 {
  width: 100%;
  /* Take up all available space */
}

/* Right column (Overlay) */
.unity_col-lg-4 {
  position: absolute;
  /* Position col-4 absolutely */
  top: 0;
  right: 0;
  width: 400px;
  /* Set a fixed width for col-4 */
  background-color: rgba(255, 0, 0, 0);
  background: rgba(0, 0, 0, 0.0);
  margin-right: 90px;
  /*
  padding: 20px; /* Add padding to create some space around the content (optional) */
}

/* Overlay content (text box, buttons, etc.) */
.unity__overlay {
  display: flex;
  /* Use flexbox */
  flex-direction: column;
  /* Stack the content vertically */
  height: 100%;
  /* Set the height to 100% to fill the left column */
}

/* Hide Ui */
@media screen and (max-width: 1200px) {
  .unity__frame_size {
    display: none;
    border: 5px;
  }
}


/* ------- UNITY MOBILE UI --------- */

/* Full container holding all buttons for Unity Mobile UI NOT FLEX CONTAINER */

.unity__mobie_fullcontainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}



/* Description row - This is for the pop up background for description text*/
.unity__mobile_description {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 255, 255, 0.3);
  min-height: 80%;
  padding: 20px;
  color: var(--color-white);
  overflow: auto;
  max-height: 80vh;
}

/* START ScrollBar - This is for the UI scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--sn-orange);

}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;

}

*::-webkit-scrollbar-track {
  background:  rgba(0, 0, 0, 0.0);
  scroll-padding-top: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--sn-orange);
  border-radius: 8px;
  border: 0px solid #ffffff;


}/* END ScrollBar - This is for the UI scrollbar */


/* NO CONTENT - This is for the box that shows when content is not available */
.no__content {

  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 255, 255, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 60px;
  min-width: 130px;
  width: 100%;
  color: var(--color-white);
}

.no__content p {
  text-align: center;
  padding-top: 18px;
}

/* THIS IS THE START OF ALL THE FLEX BUTTON CONTAINERS */

/* This is parent container 1 for the flex-box buttons */
.unity__mobile_flex-container1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;

  }

/* These are the child elements for parent container 1 flex box */
.flex-item1 {
  order: 1;
  flex-grow: 0 ;
  flex-shrink: 2;
  margin-right: 5px;
  }

.flex-item2 {
  order: 2;
  flex-grow: 6 ;
  margin-top: 5px;
  }


/* This is parent container 2 for the flex-box buttons */

.unity__mobile_flex-container2 {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    }

/* These are the child elements for parent container 2 flex box */  

.flex-item3 {
    order: 1;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
  }

.flex-item4 {
    order: 2;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
    }
.flex-item5 {
    order: 3;
    flex: 1 0 auto;
    align-self: auto;
    }

/* This is parent container 3 for the flex-box buttons */

.unity__mobile_flex-container3 {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    }

/* These are the child elements for parent container 3 flex box */  

.flex-item6 {
    order: 1;
    flex: 3 0 auto;
    align-self: auto;
    margin-right: 5px;
  }

.flex-item7 {
    order: 2;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
    }
.flex-item8 {
    order: 3;
    flex: 1 0 auto;
    align-self: auto;
    }

