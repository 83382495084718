/*--------------------------
    Copyright Area  
---------------------------*/


// Copyright Style One 
.copyright-style-one {
    background-color: transparent!important;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap-reverse;
    justify-content: space-between; /* Use space-between to align left and right */
    align-items: center; /* Vertically center the content */
    padding: 20px;
}

.container_disclaimer {
    padding-left: 10px;
    display: block;
    flex: 1;
    align-self: flex-start; /* Align to the top (left) */
    order: 0;
}

.container_text {
    display: block;
    align-self: flex-end; /* Align to the bottom (right) */
    order: 0;
}



/* Set the main container to use flexbox and occupy the full viewport height */
.page-wrapper {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
  
  /* Define the content area to grow and occupy all available space */
  .home {
    flex-grow: 1;
  }
  
  /* Styling for the footer */
  .footer__ancor {
    position: sticky;
    bottom: 0;
    background-color: var(--color-blackest);
    z-index: 999;
  }
  
  
  

  

