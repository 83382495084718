/* Add padding to the top of the main content area */


.category-selection {

margin-top: 20px;

}


/* Style for the modal content */
.custom-modal-content {
    width: 80%; /* Set width to 80% of the viewport */
    max-width: 800px; /* Limit maximum width for larger screens */
    background-color: #000; /* Black background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
    position: fixed; /* Fixed positioning to cover the entire viewport */
    top: 50%; /* Move content 50% down from the top */
    left: 50%; /* Move content 50% from the left */
    transform: translate(-50%, -50%); /* Adjust position to center both horizontally and vertically */
    color: #fff; /* Text color */
  }
  
  /* Style for the video */
  .custom-modal-content video {
    width: 100%; /* Ensure the video fills the modal width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensure proper alignment */
    margin: 0 auto; /* Center horizontally */
  }
  
  /* Style for the close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  
  /* Style for the button row */
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Style for the modal button */
  .modal-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #000000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-button:hover {
    background-color: var(--sn-orange);
    color: #fff;
  }
  
  /* Style for the thumbnail image */
  .video-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  /* Style for the video title */
  .video-item h3 {
    text-align: center;
    margin-top: 10px;
  }
  

  .video-item {
    margin-top: 20px;
    text-align: center; /* Center align the content */
  }
  
  .thumbnail-image {
    width: 100%; /* Set width to fill its container */
    max-width: 80%; /* Limit maximum width to prevent resizing */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Add rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  .thumbnail-title {
    margin-top: 10px; /* Add space between the image and title */
  }

  .embed-copied {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
  }
  