/*-------------------- CSS for filter buttons -------------------- */
.rwt-portfolio-filter button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  line-height: 20px;
  border-radius: 4px;
  padding: 13px 23px;
  border: none;
  /* Remove the solid border */
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  margin-left: 5px;
  margin-right: 5px;
  color: #222222;
  /* Set default text color */
  background-color: var(--color-white);
  /* Set default background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  /* Add the drop shadow */
  margin-top: 30px;
}
.dark .rwt-portfolio-filter button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  line-height: 20px;
  border-radius: 4px;
  padding: 13px 23px;
  border: none;
  /* Remove the solid border */
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  margin-left: 5px;
  margin-right: 5px;
  color: #222222;
  /* Set default text color */
  background-color: var(--color-darker-foreground);
  /* Set default background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  /* Add the drop shadow */
  margin-top: 30px;
}

/* Hover and current button styles for light mode */
.rwt-portfolio-filter button:hover,
.rwt-portfolio-filter button.current {
  border-color: #000000;
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--color-white) !important;
  /* Set text color to white for hover and current */
}

/* Hover and current button styles for dark mode */
.dark .rwt-portfolio-filter button:hover {
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--color-white) !important;
  /* Set text color to white for hover and current */
}

/* Default background color for filter buttons in dark mode */
.dark .rwt-portfolio-filter button {
  color: var(--color-white) !important;
}

/* Add this style to remove default button styles (optional) */
.rwt-portfolio-filter button:focus {
  outline: none;
}

/* Filter Button */
.filter-button-default {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  /* Remove the default bullet points */
}

.filter-button-default li button {
  border-radius: 12px !important;
  border-style: none !important;
}

/*-------------------- CSS for Unity Button -------------------- */

/* Unity Buttons */
.unity__button {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 255, 255, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 40px;
  width: 100%;

  color: var(--color-white);
  /* COLOUR FOR BUTTON TEXT */
}

.unity__button:hover,
.unity__button.current {
  border-color: #000000;
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--color-white) !important;
  /* Set text color to white for hover and current */
}

/* Hover and current button styles for dark mode */
.dark .unity__button:hover {
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--darkest-grey) !important;
  /* Set text color to white for hover and current */
}

/* Default background color for filter buttons in dark mode */
.dark .unity__button {
  background: rgba(75, 75, 75, 0.4);
  border-radius: 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 255, 255, 0.3);

  color: var(--color-white);
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 40px;
  min-width: 130px;
  width: 100%;
}

/* Add this style to remove default button styles (optional) */
.unity__button:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  .unity__button-container-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* Make it full width */
  }



  .unity__button-container>* {
    flex-basis: calc(5% - 10px);
    text-align: center;
    /* Center the text within each button */
  }

  /* Override existing styles with !important to center the buttons */
  /* .unity__button-container .unity__button {

  } */

}

/* Optional: Adjust spacing between the buttons for larger screens */
@media screen and (min-width: 768px) {

  .unity__button {

    background: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    min-height: 40px;
    width: 100%;
    color: var(--color-white);

  }


  .unity__button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .unity_button1 {
    order: 1;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
  }

.unity_button2  {
    order: 2;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
    }
.unity_button3 {
    order: 3;
    flex: 1 0 auto;
    align-self: auto;
    }

}


/* New CSS for .unity__button-container_full */
.unity__button-container_full {
  display: flex;
  /* Use flexbox */
  justify-content: center;
  /* Center the buttons horizontally */
  align-items: center;
  /* Center the buttons vertically */
}

.unity__button-container_full>* {

  /* Each button takes 25% of the container width, without wrapping to the next line */
  margin: 2px;
  /* Add some spacing between the buttons */
  text-align: center;
  /* Center the text within each button */

  margin-top: 60px;
}

.unity__button-container_full {
  margin-top: 80px;
}

/* Popup Close Button */

.popup__close-button {
  background-color: var(--sn-orange) !important;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  margin-left: 80%;
}

/* Info Button CSS */

.info__button {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 255, 255, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;

  color: var(--color-white);
  padding: 10px;
  /* COLOUR FOR BUTTON TEXT */
}

.info__button>img {
  width: 30px;
  padding: 2px
}

.info__button:hover,
.info__button.current {
  border-color: #000000;
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--color-white) !important;
  /* Set text color to white for hover and current */
}



/* Hover and current button styles for dark mode */
.dark .info__button:hover {
  background-color: var(--sn-orange) !important;
  /* Change background color to orange for hover and current */
  color: var(--darkest-grey) !important;
  /* Set text color to white for hover and current */
}

/* Default background color for filter buttons in dark mode */
.dark .info__button {
  background: rgba(75, 75, 75, 0.4);
  border-radius: 100%;
  backdrop-filter: blur(11.8px);
  -webkit-backdrop-filter: blur(11.8px);
  border: 0px solid rgba(255, 255, 255, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;

  color: var(--color-white);
  padding: 10px;
}

.info__button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

/* Padding between the two round buttons */
.info__button {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .info__button {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    margin-top: 5px;
    margin-bottom: 5px;

    color: var(--color-white);
    padding: 5px;
    /* COLOUR FOR BUTTON TEXT */
  }
}

/* HAMBERGER MENU BAR BUTTON FOR MOBILE */

