/* Design CSS for Tutorial boxes */

.tutorial__container {
    background: rgba(255, 136, 0, 0.712)!important;
    border-radius: 12px!important;
    backdrop-filter: blur(11.8px)!important;
    -webkit-backdrop-filter: blur(11.8px)!important;
    border: 0px solid rgba(255, 255, 255, 0.3)!important;
    padding: 20px!important;
    color: white!important;
}


.tutorial__button {
    background: rgb(255, 255, 255)!important;
    border-radius: 12px!important;
    backdrop-filter: blur(11.8px)!important;
    -webkit-backdrop-filter: blur(11.8px)!important;
    border: 0px solid rgba(255, 255, 255, 0.3)!important;
    margin-top: 5px!important;
    margin-bottom: 5px!important;
    min-height: 40px!important;
    min-width: 130px!important;
    width: 100%!important;
  
    color: var(--sn-orange)!important;
    /* COLOUR FOR BUTTON TEXT */
}

.tutorial__button:hover,
.tutorial__button.current {
  border-color: #000000!important;
  background-color:rgb(255, 255, 255)!important;
  /* Change background color to orange for hover and current */
  color: black !important;
  /* Set text color to white for hover and current */
}

.tutorial__container li {
    color: white!important;
    font-weight: 300!important;
    font-size: 16px!important;
    
}

.tutorial__container p {
    color: white!important;
    font-weight: 300!important;
    font-size: 18px!important;
    
}

/* Anchor points CSS  */


#tutorial__anchor_buttons{ 

}
#tutorial__anchor_renderbutton{ 


}
#tutorial__anchor_embedbutton{ 


}
#tutorial__anchor_downloadbutton{ 


}
#tutorial__anchor_slider{ 

    background-color: transparent !important;
    height: 60px;
    width: 408px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;

}

#tutorial__anchor_backbutton{  

    background-color: transparent !important;
    height: 500px;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;

}
