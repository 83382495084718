.rotate__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Adjust the height as needed */
  position: relative;
}

.rotate__center {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate-center 0.6s ease-in-out infinite both;
  animation-fill-mode: forwards;
  margin-top: 80px;
  margin-left: 40px;
  z-index: 2;

  /* Adjust the size of the rotate__center div to match the #Plus path */
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */

  /* Center the axis of rotation */
  transform-origin: center;
}

#Plus {
  fill: #fd9400;
  transform-origin: center;
  transform: scale(0.6); /* Adjust the scale of the Plus PATH */
}

.rotate__center > svg { /* This controls the box the SVG path is in */
  height: 200px;
}

.rotate__static {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Colours for SVG Paths */

.cls-1 {
  transform-origin: center;
  width: 368px;
  height: 200px;
  fill: #fd9400;
}
