:root {
    --main-color:  rgb(245, 156, 37);
    --main-color-hover:  rgba(245, 155, 37, 0.24);
    --track-color: #ddd;
    --mark-color: #757575;
  }

  .SSRSlider_Flex{
    display: flex;
    width: 100%;
  }

.ScreenshotResSlider {

max-width: 340px;
margin-left: auto;
margin-left: 10px;
width: 100%;
min-width: 80px;


  }
  
  .SSRSlider-track {
    /* Top value to align your track to the center of your thumb */
    top: 8px;
    /* thickness of the track */
    height: 4px;
   /* default color of your track */
    background: var(--track-color);
  }
  
  .SSRSlider-track.SSRSlider-track-0 {
    /* color of the track before the thumb */
    background: var(--main-color);
  }

  .SSRSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: var(--main-color);
    /* shape of the thumb: circle */
    width: 20px;
    height: 20px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
  }
  
  .SSRSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
  }

  .SSRSlider-mark {
    cursor: zoom-in;
    top: 6px;
    width: 1.5px;
    height: 8px;
    background-color: var(--mark-color);
  }

  .SSRSlider-Value {
    color: white;
    width: 1px;
    height: 1px;
  }

  .unity__slider_Mobile {



    background: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    height: 40px;
    color: var(--darkest-grey);
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* SCREENSHOT TEXT */

  .screenshot_text p {
    color: white;
  }

  /* Media Query for Unity Slider Mobile container size and padding */

  @media screen and (max-width: 767px) {
  .unity__slider_Mobile {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    height: 60px;
    color: var(--darkest-grey);
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.screenshot_text p {
  color: white;
  margin-bottom: 2px;
  margin-top: 2px;
}
  }
  @media screen and (max-width: 767px) {
  .dark .unity__slider_Mobile {
    background: rgba(75, 75, 75, 0.4);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    height: 60px;
    color: var(--darkest-grey);
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.dark .screenshot_text p {
  color: white;
  margin-bottom: 2px;
  margin-top: 2px;
}
  }

