#darkmode {
    border: 0 none;
    padding: 0;
    margin-left: 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    border-radius: 100%;
    background-color: var(--sn-orange);
    border: none;
    cursor: pointer;


  }
  

.icon {
  width: 24px;
  height: 24px;
}

.light-icon {
  width: 16px; /* Set the desired width for the light mode icon */
  height: 16px; /* Set the desired height for the light mode icon */
}


/* UNITY UI FOR MOBILE DARK-MODE  */

/* THIS IS THE START OF ALL THE FLEX BUTTON CONTAINERS */

/* This is parent container 1 for the flex-box buttons */
.dark .unity__mobile_flex-container1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;

  }

/* These are the child elements for parent container 1 flex box */
.dark .flex-item1 {
  order: 1;
  flex-grow: 0 ;
  flex-shrink: 2;
  margin-right: 5px;
  }

  .dark .flex-item2 {
  order: 2;
  flex-grow: 6 ;
  margin-top: 5px;
  }


/* This is parent container 2 for the flex-box buttons */

.dark .unity__mobile_flex-container2 {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    }

/* These are the child elements for parent container 2 flex box */  

.dark .flex-item3 {
    order: 1;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
  }

  .dark .flex-item4 {
    order: 2;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
    }
    .dark .flex-item5 {
    order: 3;
    flex: 1 0 auto;
    align-self: auto;
    }

/* This is parent container 3 for the flex-box buttons */

.dark .unity__mobile_flex-container3 {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    }

/* These are the child elements for parent container 3 flex box */  

.dark .flex-item6 {
    order: 1;
    flex: 3 0 auto;
    align-self: auto;
    margin-right: 5px;
  }

  .dark .flex-item7 {
    order: 2;
    flex: 1 0 auto;
    align-self: auto;
    margin-right: 5px;
    }
    .dark .flex-item8 {
    order: 3;
    flex: 1 0 auto;
    align-self: auto;
    }

    