/* Import the font */
@font-face {
    font-family: 'SNEmeric-Core';
    src: url('../../fonts/SNEmeric-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Apply the font to the body */
  body {
    font-family: 'SNEmeric-Core', sans-serif;
  }
  
  /* Add specific styles for different elements */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'SNEmeric-Core', sans-serif;
    /* Add additional styling properties as needed */
  }
  
  p {
    font-family: 'SNEmeric-Core', sans-serif;
    /* Add additional styling properties as needed */
  }
  
  /* H tag style colour */

  .h3_white {
    color: aqua !important;
  };

  /* Header Font */

  li {
    color: black;

    }

    .dark li {
      color: white !important;
    }

.orange__colour{
  color: var(--sn-orange);
}


  