/* CARD STYLING */
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0px !important;
  background-color: transparent !important; /* Updated to transparent */
  cursor: pointer; /* Added to show pointer cursor on hover */


}

.card__container {
  margin: 10px;
  border: 0px solid black;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.25);
  transition: transform 200ms ease-in-out;
  width: 100%;
  /* Updated */
  max-width: 250px;
  display: flex;
  /* Added */
  flex-direction: column;
  /* Added */
  background-color: var(--color-white); /* Default background color for light mode */
}


/*Hover event for the Cards - Apply to Selected Card*/
.card__container:hover {
  transform: scale(1.06) !important;
  filter: none !important;
}

/* Dark Mode Text Color */
.dark .card__info > h2,
.dark .card__info > h4,
.dark .card__info > h5,
.dark .card__link {
  color: #fff; /* Set the text color to white in dark mode */

}

/* Dark Mode */
.dark .card__container {
  background-color: #222222;
  /* Add your dark mode styles for .card__container here */
}

.card__image{
  background-color: rgb(244, 244, 244);
  
}

.dark .card__image{
  background-color: var(--darkest-gray);
}

.card > img {
  
  object-fit: cover;
  /* Updated */
  width: 100%;
  height: 200px;
  /* Adjust as needed */
  
}

.card__info {
  background-color: var(--color-primary-light);
  padding: 20px;
  padding-top: 20px;
  border: 1;
  width: 100%;
  /* Updated */
height: 112px;
  max-height: 150px;
  /* Updated */
  overflow: hidden; /* Updated to prevent text cutoff */
  white-space: normal; /* Updated to allow wrapping of text */
  
}
.dark .card__container {
  margin: 10px;
  border: 0px solid black;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.25);
  transition: transform 200ms ease-in-out;
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-darker-foreground);
}

.dark .card__image {
  background-color: var(  --darker-grey);
}

.card__info > h2 {
  font-size: 18px;
  font-weight: 600;
  margin-top: -4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
}

.card__info > h4,
.card__info > h5 {
  font-size: 14px;
  font-weight: 400;
  margin-top: -6px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}


.dark .card__info > h5 {
  color: #FF9E18;
}

.card__link {
  text-decoration: none;
  color: black;
  cursor: pointer; /* Added to show pointer cursor on hover */
}

.card__link:hover {
  text-decoration: none;
}

/* Load More Button */
.load-more > button {
  display: flex;
  margin-top: 20px;
  margin-left: 40%;
  justify-content: center;
  align-items: center;
  appearance: none;
  background-color: #FF9E18;
  border-radius: 24px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  fill: currentcolor;
  height: 38px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.load-more > button:hover {
  background: #F6F9FE;
  color: #65676b;
}

.load-more > button:active {
  outline: none;
}

.load-more > button:focus {
  outline: none;
  border: 2px solid #FF9E18;
}

.catagory__box {
  width: 60px;
  background-color: var(--sn-orange);
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 3px;
  position: relative;
  bottom: 4px;
  right: -187px;
}

.catagory__box h5 {
  padding-top: 4px;
  color: white;
  font-size: 12px;
  margin: 0px;
  text-align: center;
  line-height: 10px;
}




