/* Updated Popup Menu Styles */
.popup-mobile-menu {
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 90%; /* Set to 50% of the screen height */
    bottom: 0; /* Position it at the bottom of the screen */
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.popup-mobile-menu .inner {
    width: 100%;
    z-index: 999;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%; /* Occupy the full 50% height of the popup */
    display: flex;
    flex-direction: column;
    opacity: 0;
    bottom: -50%; /* Initial position off the screen */
    transform: translateY(100%);
    transition: all 0.5s ease-out;
}

.popup-mobile-menu.active {
    visibility: visible;
    opacity: 1;
}

.popup-mobile-menu.active .inner {
    opacity: 1;
    bottom: 0; /* Move the popup up to display 50% */
    transform: translateY(0);
    animation: none; /* Remove animation when it's already active */
}

.popup-mobile-menu .mainmenu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 15px 20px;
}

.popup-mobile-menu .mainmenu li {
    margin: 0;
}

.popup-mobile-menu .mainmenu li a {
    padding: 8px 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.popup-mobile-menu .mainmenu li + li {
    border-top: 1px solid var(--color-border);
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu {
    height: 0;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu li a {
    font-size: 15px;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu.active {
    height: 100%;
    opacity: 1;
    visibility: visible;
    padding-bottom: 12px;
}

.popup-mobile-menu .mainmenu .rn-megamenu {
    height: 0;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
}

.popup-mobile-menu .mainmenu .rn-megamenu.active {
    height: 100%;
    opacity: 1;
    visibility: visible;
    padding-bottom: 12px;
}

.popup-mobile-menu .mainmenu .mega-menu-item {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0;
}

.popup-mobile-menu .mainmenu .mega-menu-item li a {
    font-size: 15px;
}

.popup-mobile-menu .mainmenu .mega-menu-item li:last-child {
    border-bottom: 1px solid var(--color-border);
}

.popup-mobile-menu .mainmenu .single-mega-item:last-child .mega-menu-item li:last-child {
    border-bottom-color: transparent;
}
