/* Hero container covers the entire viewport */
.hero {
    position: relative;
    width: 100%;
    height: 100vh; /* 100% viewport height */
    overflow: hidden; /* Hide overflowing video content */

  }
  
  /* Video element styles */
  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the entire container */
    z-index: -1; /* Place the video behind other content */
    background-color: black;
  }
  
  /* Hero content styles (text overlay) */
  .hero-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for text */
    color: #ffffff; /* Text color */
  }
  
  /* Splash text styles */
  .splash-text {
    font-size: 4rem; /* Adjust the font size as needed */
    font-weight: bold;
    margin: 0;
    padding: 20px; /* Add padding for better readability */
  }
  