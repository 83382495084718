/* Style the text box */
.unity__info_textarea {
  background: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    min-height: 60px;
    margin-top: 100px;
    padding: 20px;
    color: var(--color-white);

    overflow: auto;
    max-height: 210px;
  }

    /* ScrollBar */
    * {
      scrollbar-width: thin;
      scrollbar-color: var(--sn-orange);

    }
  
    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 5px;

    }
  
    *::-webkit-scrollbar-track {
      background: transparent;
      scroll-padding-top: 20px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  
    *::-webkit-scrollbar-thumb {
      background-color: var(--sn-orange);
      border-radius: 8px;
      border: 0px solid #ffffff;
     
      
    }

    /* Dark mode text box */
    .dark .unity__info_textarea  {
        background: rgba(75, 75, 75, 0.4);
        border-radius: 12px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(11.8px);
        -webkit-backdrop-filter: blur(11.8px);
        border: 0px solid rgba(255, 255, 255, 0.3);
        min-height: 60px;
        color: var(--colour-white);
        padding: 20px;
      }
  
  .unity__info_textarea h3 {
    margin-bottom: 10px;
  }
  
  .unity__info_textarea p {
    margin-bottom: 0;
  }
  
  .unity__textbox_area {

    background: rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    min-height: 60px;
    margin-bottom: 5px;
    padding: 20px;
    
  }
  
  /* Apply the dark mode styles to the textarea */
  .dark .unity__textbox_area textarea {
    resize: none; /* Remove the resize option */
    border: none; /* Remove the border */
    box-shadow: none; /* Remove the box shadow from the textarea */
    color: #fff; /* Set text color for dark mode */
 ; /* Set background color for dark mode */
  }
  
  /* Reset textarea styles */
  .unity__textbox_area textarea {
    border: 5px; /* Remove border */
    resize: none; /* Remove resizer */
    outline: none; /* Remove outline */
    width: 100%; /* Make the textarea full width */
    height: 100%; /* Make the textarea full height */
    font-family: inherit; /* Use the parent font-family */
    font-size: inherit; /* Use the parent font-size */
    line-height: inherit; /* Use the parent line-height */
    box-shadow: none; /* Remove box shadow */
    background-color: transparent; /* Set background color to transparent */
    color: var(--color-white);  /* THIS CHANGES COLOUR OF EMBED TEXT AREA FONT */

  }
  
  /* Dark mode text box */
  .dark .unity__textbox_area  {
    background: rgba(75, 75, 75, 0.4);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.8px);
    -webkit-backdrop-filter: blur(11.8px);
    border: 0px solid rgba(255, 255, 255, 0.3);
    min-height: 60px;
    color: var(--darkest-grey);
    padding: 20px;
  }

  .unity__textCSSFix {
    white-space: pre-wrap;

  }
  
  /* Media query for small devices
  @media screen and (max-width: 1000px) {
    .unity__textbox_area {
        margin-bottom: 22px;
    }
  } */
  