/* HEader Styling */

.rn-header {
  position: fixed !important;
  top: 0!important;
  left: 0!important;
  width: 100%!important;
  z-index: 2!important; /* Set a higher z-index to keep it above other elements */

}

.header-top-news {
  position: relative!important; /* Set to relative positioning */
  z-index: 1!important; /* Set a lower z-index to keep it behind other elements */
}

/* Other existing styles for the HeaderTopNews component */



/* HOME STYLING */
/* RAINBOW TEXT */
@keyframes rainbowTextAnimation {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

body {
  overflow-x: hidden !important;

}
.rainbow-text {
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(90deg, #12c2e9, #c471ed, #f64f59, #12c2e9, #c471ed, #f64f59);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: rainbowTextAnimation 8s linear infinite;
  background-size: 300% 100%;
}

.home__section {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

@media screen and (max-width: 767px) {
  .home__section  {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }
}

.home__section__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content-item-wrapper {
  align-items: center;
  max-width: 100%;
}

.ortho__section {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 30px;
  padding-left: 18vw;
}

.unity__ui__container {
  border-radius: 20px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  background-color: red;
  margin-right: 30px;
  height: 80%;
}

/* Loading Screen Style */

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Disclaimer Page */

.disclaimer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  margin-right: 40px;
  margin-left: 40px;
}

/* Media query for small devices */
@media screen and (max-width: 767px) {
 .dark .home__section__content {
    display: flex;
    flex-direction: column; /* Ensure cards stack vertically */
  }

  .dark .card {
    width: 100%; /* Ensure each card takes up the full width */
    margin: 0px 0; /* Add vertical margin to separate cards */
  }

  .dark .card__container {
    flex-direction: row; /* Keep image and info side by side */
    align-items: center; /* Center content vertically */
    max-width: 500px; /* The Width of the card container */
    max-height: 150px;
  }

  .dark .card img {
    width: 100%; /* Image takes 40% of the width */
  }

  .dark .card__info {
    width: 100%; /* Info takes the remaining 60% */
  }

  /* Light Mode */
 .home__section__content {
    display: flex;
    flex-direction: column; /* Ensure cards stack vertically */
  }

 .card {
    width: 100%; /* Ensure each card takes up the full width */
    margin: 0px 0; /* Add vertical margin to separate cards */
  }

 .card__container {
    flex-direction: row; /* Keep image and info side by side */
    align-items: center; /* Center content vertically */
    max-width: 500px; /* The Width of the card container */
    max-height: 150px;
  }

 .card img {
    width: 100%; /* Image takes 40% of the width */
  }

 .card__info {
    width: 100%; /* Info takes the remaining 60% */
  }
}
