.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 9998; /* Set a value lower than the popup to place it behind the popup */
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    max-width: 500px;
    width: 80%;
    z-index: 9999; /* Set a high z-index value to place the popup on top of everything */
  }
  .dark .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #232323;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    max-width: 500px;
    width: 80%;
    z-index: 9999; /* Set a high z-index value to place the popup on top of everything */
  }


  
  .popup-content {
    text-align: center;
  }
  
  .popup h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .dark .popup-text-content p {
    font-size: 14px;
    color: rgb(255, 255, 255) !important;
}

.dark .popup h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: rgb(255, 255, 255) !important;
}
  
  .popup-text {
    margin-bottom: 20px;
  }
  
  .popup-text-content p {
    font-size: 14px;
  }
  
  button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  